import React, { Component } from 'react'
import HorizontalScroll from 'react-scroll-horizontal'
import Canvas from './Canvas'

const Newsliderscroll = () => {
    
   return (
<div className="maincontainer">
<div className="newslidercontent">
<HorizontalScroll reverseScroll ={true}>

<div className ="slider">
<div className="herotitleholder">
     <div className="titleholder">
        <h1>Qreative</h1>
        <br></br>
        <h1>Quirky</h1>
        <br></br>
        <h1>Design</h1>
        <br></br>
        </div>
        <div className="subtitleholder">
            <img src={require("./images/subtitle.png")} alt="" />
            </div>       
</div>
<div className="contentcanvasfirst">
<div className="overlapcontent">
<div className="descriptorholder">
 <h2 class="internaltitletext"><span>'Trick or Treat' <br></br> Tshirt Series</span></h2>
 <h3 class="internalsubtitletext"><span>Merch</span></h3>
 </div> 
<Canvas id={"halloween"} width={300} height={300}></Canvas>
</div>
<div className="canvasholder">
 <div className="descriptorholder">
 <h2 class="internaltitletext"><span>‘Fight or Die’ <br></br> Poster Series</span></h2>
 <h3 class="internalsubtitletext"><span>the way tshirts</span></h3>
  </div> 
<Canvas id={"starwars"} width={600} height={600}></Canvas>
</div>
</div>
<div className="canvasholder">
<div className="descriptorholder">
 <h2 class="internaltitletext"><span>'International Day Against Homophobia, Biphobia, <br></br> Lesbophobia and Transphobia'</span></h2>
 <h3 class="internalsubtitletext"><span>Art work</span></h3>
 </div> 
<Canvas id={"trans"} width={600} height={600}></Canvas>
</div>
</div>
<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
    <filter id="gooeysvg">
      <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
      <feColorMatrix in="blur" mode="matrix"
        values=" 1 0 0 0 0
                 0 1 0 0 0
                 0 0 1 0 0
                 0 0 0 20 -7" result="gooeysvg" />
      <feComposite in="SourceGraphic" in2="gooeysvg" operator="atop"/>
    </filter>
  </defs>
</svg>

</HorizontalScroll>
</div>
</div>
    );
}
 
export default Newsliderscroll;