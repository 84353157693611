import React, { Component } from 'react'
import HorizontalScroll from 'react-scroll-horizontal'
import Canvas from './Canvas'
import { useState } from 'react';
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import { useShoppingCart } from './CartContext';
import storeItems from '../src/data/product.json';
import Tshirtimage from './tshirt';
import { FormatCurrency } from './FormatCurrency';

const Productpage = () => {
    const containervariants = {
        hidden : {opacity: 0, x: "100%"}, 
        visible : { opacity: 1, x: "0%",
        transition :{ease:"easeInOut", type: "spring",
        stiffness: 260, damping: 20} }, 
        exit:{ x: "-100%", transition:{ ease: "easeInOut"} }
       } 

       const hiawathaproduct = {      
        name:"Curves and Curses T-Shirt",
        description: "From spookily soft sweatshirts to chillingly comfy tees, you're sure to find the perfect fit to wear out on Halloween!",
        price: 20.50,
      }

    const{
        getItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        removeFromCart
      } =useShoppingCart()

   
      const [color,setColor] = useState('Purple');
      const [size,setSize] = useState('M');
      const [id,setID] = useState(1)

      function colourbuttonclick(id,color,e){
        if(e.target.className != "productcolorchangerbutton"){   
        const allcolorpickerholders = e.target.parentNode.parentNode.parentNode.getElementsByClassName("productcolourimage")
        for (let i = 0; i < allcolorpickerholders.length; i++){
           allcolorpickerholders[i].style.backgroundColor = "rgba(255, 0, 0, 0.0)";
        } 
        const colorpickerholder = e.target;
         
        colorpickerholder.style.backgroundColor = "white";        
        setColor(color)      
        setID(id)
        }
      }

      function sizebuttonclick(size,e){
        if(e.target.className != "productsize" && e.target.className != "productcolorchangerbutton"){   
        const allsizepickerholders = e.target.parentNode.parentNode.parentNode.getElementsByClassName("productsizeholder")
        
        for (let i = 0; i < allsizepickerholders.length; i++){            
            allsizepickerholders[i].style.backgroundColor = "rgba(255, 0, 0, 0.0)";
            allsizepickerholders[i].firstChild.style.color = "white";
            
        } 
        const sizepickerholder = e.target;
        const sizetext = sizepickerholder.firstChild;     
        sizepickerholder.style.backgroundColor = "white";
        sizetext.style.color = "black";       
        setSize(size)
        }
      }
      
   return (
<motion.div className="maincontainer" variants={containervariants} initial="hidden" animate="visible" exit="exit">
<div className="productnewslidercontent">
<div className="herotitleholdermerch">
    <div className="heroimage1">
   <Tshirtimage color={color}></Tshirtimage>
   </div>   
</div>
<div className="ProductMerchinfoholder">
 <div className="producttitle">{hiawathaproduct.name}</div>  
 <div className="productdescriptionholder">
 {hiawathaproduct.description}
</div>
<div className="productcolumnsection">
<div className="colorandsizesection">
<div className="productsectiontitle">Color</div>
<div className="productcolourpicker">
    <div className="productcolour">
         <button  className="productcolorchangerbutton" onClick={(e)=>colourbuttonclick(1,'Purple', e)}>   
        <img src={require("./images/My project - 2023-05-17T162732.106.png").default} alt="testingimage" className="productcolourimage" />
        </button>
        <div className="colourname">Purple</div>
    </div>
    <div className="productcolour">
        <button  className="productcolorchangerbutton" onClick={(e)=>colourbuttonclick(2,'Orange',e)}>
        <img src={require("./images/My project - 2023-05-23T172552.107 (2) (2).png").default} alt="" className="productcolourimage" />
        </button>
        <div className="colourname">Orange</div>
    </div>
    <div className="productcolour">
        <button className="productcolorchangerbutton" onClick={(e)=>colourbuttonclick(3,'Green',e)}>
        <img src={require("./images/My project - 2023-05-23T172647.729 (1) (1).png").default} alt="" className="productcolourimage" />
        </button>
        <div className="colourname">Green</div>
    </div>
    <div className="productcolour">
    <button className="productcolorchangerbutton" onClick={(e)=>colourbuttonclick(4,'Cyan',e)}>
        <img src={require("./images/My project - 2023-05-23T173048.898 (1) (1).png").default} alt="" className="productcolourimage" />
        </button>
        <div className="colourname">Cyan</div>
    </div>   
</div>
<div className="productsectiontitle">Size</div>
<div className="productsizepicker">
<button className="productcolorchangerbutton" onClick={(e)=>sizebuttonclick('XS',e)}>
    <div className="productsizeholder">
        <div className="productsize">XS</div>
    </div>
    </button>
    <button className="productcolorchangerbutton" onClick={(e)=>sizebuttonclick('S',e)}>
    <div className="productsizeholder">
        <div className="productsize">S</div>
    </div>
    </button>
    <button className="productcolorchangerbutton" onClick={(e)=>sizebuttonclick('M',e)}>
    <div className="productsizeholder">
        <div className="productsize">M</div>
    </div>
    </button>
    <button className="productcolorchangerbutton" onClick={(e)=>sizebuttonclick('L',e)}>
    <div className="productsizeholder">
        <div className="productsize">L</div>
    </div>
    </button>
    <button className="productcolorchangerbutton" onClick={(e)=>sizebuttonclick('XL',e)}>
    <div className="productsizeholder">
        <div className="productsize">XL</div>
    </div>
    </button>
</div>
</div>
<div className="shippingandnumberofitems">
    <div className="priceholder">
    <div className="productsectiontitleleft">Price</div>
        <div className="price">{FormatCurrency(hiawathaproduct.price)}</div>
    </div>
<div className="shippinginfo">
    <div className="productsectiontitleleft">Shipping info</div>
    <div className="shippingwarning">** Free Shipping in the US </div>
    <div className="shippingwarning">** No international shipping</div>
</div>
<div className="purchasebuttonholder">
 <div className="addtocartbuttonholder">
          <div className="buttoninner"></div>
          <button className='addtocartCTAbutton' onClick={()=>increaseCartQuantity(id,size,color)}>Add to Cart</button>
          </div>
 </div>


</div>
</div>

</div>
</div>

</motion.div>
    );
}
 
export default Productpage;