import './App.css';
import React from 'react'
import Navbar from './Navbar';
import Home from './Home';
import Newblog from './New Blog';
import { useState } from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import Horizontal from './horizontalscroll';
import Newsliderscroll from './newslider';
import FightorDie from './Fightordie';
import Trans from './Trans';
import Halloween from './Halloween';
import Tara from './Tara';
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import Merch from './merch';
import About from './about';
import Coffee from './Coffee';
import Contact from './Contact Form';
import Productpage from './Productpage'
import { ShoppingCartProvider } from './CartContext';
import Shoppingcart from './Shoppingcart';
import Checkoutpage from './Checkoutpage';
import Checkoutshippingform from './Checkoutshippingform';

function App() {
  const location = useLocation()   
    return (
  
<ShoppingCartProvider>
    <div className="App">
      <header className="App-header">
        <Navbar></Navbar>            
       <div className="content">
       <AnimatePresence mode={"wait"}> 
        <Routes location={location} key={location.key}>
          <Route path='/' element={<Home/>}>    
          </Route>
          <Route path='/create' element={<Newblog/>}></Route>
          <Route path='/horizontalscroll' element={<Horizontal/>}></Route>
          <Route path ='/newslider' element={<Newsliderscroll/>}></Route>          
          <Route path='/FightorDie' element={<FightorDie/>}></Route>
          <Route path='/trans' element={<Trans/>}></Route>
          <Route path='/Halloween' element={<Halloween/>}></Route>
          <Route path='/Merch' element={<Merch/>}></Route>
          <Route path='/About' element={<About/>}></Route>
          <Route path='/tara' element={<Tara/>}></Route>
          <Route path='/Coffee' element={<Coffee/>}></Route>
          <Route path='/Contact' element={<Contact/>}></Route>
          <Route path='/Productpage' element={<Productpage/>}></Route>
          <Route path='/Shoppingcart' element={<Shoppingcart/>}></Route> 
          <Route path='/Checkoutpage' element={<Checkoutpage/>}></Route>
          <Route path='/Checkoutshippingform' element={<Checkoutshippingform/>}></Route>            
        </Routes>
        </AnimatePresence>    
       </div>
       </header>
    </div>  
    </ShoppingCartProvider>
  );
  
}

export default App;
