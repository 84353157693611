import React, { useEffect, useRef, useState } from 'react'
import logo from './images/Hiawatha art logo.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faXmark, faBagShopping } from '@fortawesome/free-solid-svg-icons'
import NavCanvas from './NavCanvas';
import { useShoppingCart } from './CartContext';


const Navbar = () => {

    const togglebutton = useRef(null);
    const togglebuttonicon = useRef(null);
    const dropdownmenu = useRef(null);
    const isOpen = false;
    const menucanvas = useRef(null);
    const[icons, setIcon] = useState(faBars);
    const{ cartQuantity } = useShoppingCart();
    
    return (
        <nav className="navbar">
            <Link className="logolink" to="/"><img src={logo} className="App-logo" alt="logo" /></Link>            
            <div className="links">     
                <Link to="/Halloween">Work</Link>
                <Link to="/merch">Merch</Link>
                <Link to="/about">About</Link>                
                <Link to="/Contact">Contact</Link>
                <Link to="/Shoppingcart" className='baglink'>
                   <div className="shoppingbagholder">
                    <FontAwesomeIcon icon={faBagShopping} style={{color: "#ffffff",}} className='shoppingbag'/>
                    {cartQuantity > 0 && (                        
                    <span className="quantityindicator"> 
                      <span>{cartQuantity}</span>
                    </span>
                    )}
                </div> 
                    </Link>
                         
            </div>
            <NavCanvas id={"dark"}></NavCanvas>
            <div className="togglebutton" ref={togglebutton} onClick={()=>{
               dropdownmenu.current.classList.toggle('open')
               const isOpen = dropdownmenu.current.classList.contains('open')
               //console.log(togglebuttonicon.current.getAttribute('data-icon'));
               //console.log(togglebuttonicon.current);
               dropdownmenu.current.previousSibling.previousSibling.classList.toggle('expand')
               togglebuttonicon.current.classList = isOpen
               if(isOpen){
               setIcon(faXmark)
               }
               else{
                setIcon(faBars)
               }
               //return isOpen;
 
               //</nav>? togglebuttonicon.current.setAttribute('data-icon','xmark') && togglebuttonicon.current.setAttribute('class', 'svg-inline--fa fa-xmark hamburgermenu')
               //: togglebuttonicon.current.setAttribute('data-icon','bars') && togglebuttonicon.current.classList.add('svg-inline--fa fa-bars hamburgermenu')
               
            }}>
            <FontAwesomeIcon className='hamburgermenu' icon={icons} style={{color: "#ffffff",}} ref={togglebuttonicon} size="2xl"/>
            </div>
            <div className="dropdownmenu" ref={dropdownmenu}>
                <div className="dropdownmenulink link1"><Link to="/" onClick={()=>{
                dropdownmenu.current.classList.toggle('open')
                dropdownmenu.current.previousSibling.previousSibling.classList.toggle('expand')
                setIcon(faBars)
                }} >Home</Link></div>
                <div className="dropdownmenulink link2"><Link to="/Halloween" onClick={()=>{
                dropdownmenu.current.classList.toggle('open')
                dropdownmenu.current.previousSibling.previousSibling.classList.toggle('expand')
                setIcon(faBars)
                }}>Work</Link></div>
                <div className="dropdownmenulink link3"><Link to="/merch" onClick={()=>{
                dropdownmenu.current.classList.toggle('open')
                dropdownmenu.current.previousSibling.previousSibling.classList.toggle('expand')
                setIcon(faBars)
                }}>Merch</Link></div>
                <div className="dropdownmenulink link4"><Link to="/about" onClick={()=>{
                dropdownmenu.current.classList.toggle('open')
                dropdownmenu.current.previousSibling.previousSibling.classList.toggle('expand')
                setIcon(faBars)
                }}>About</Link>  </div>              
                <div className="dropdownmenulink link5"><Link to="/Contact" onClick={()=>{
                dropdownmenu.current.classList.toggle('open')
                dropdownmenu.current.previousSibling.previousSibling.classList.toggle('expand')
                setIcon(faBars)
                }}>Contact</Link></div>
            </div>
            <Link to="/Shoppingcart" className='baglink baglinkmobile'>
                   <div className="shoppingbagholder">
                    <FontAwesomeIcon icon={faBagShopping} style={{color: "#ffffff",}} className='shoppingbag'/>
                    {cartQuantity > 0 && (                        
                    <span className="quantityindicator"> 
                      <span>{cartQuantity}</span>
                    </span>
                    )}
                </div> 
                    </Link>
        </nav>
      );
}
 
export default Navbar;
