import React from 'react'
import { WorkOndraw } from "./WorkOndraw";


const WorkCanvas = (props) => {
    const id = props.id;
    const width = props.width;
    const height = props.height;    

const setWorkCanvasRef = WorkOndraw();

    return ( 
        <canvas
        className="workcanvas"
        id = {id}
        width={width} 
        height={height}                
        ref = {setWorkCanvasRef}
        >
        </canvas>
     );
}
 
export default WorkCanvas;