import React from "react";
import { createContext, useContext, ReactNode, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { CartItem } from "./Cartitem";


type ShoppingCartContextProviderProps = {
children: ReactNode
}
type CartItem ={
    id: number
    quantity: number
    size: string
    color:string
}

type ShoppingCartContext = {
    getItemQuantity: (id:number) => number
    increaseCartQuantity: (id:number, size:string, color:string) => void
    decreaseCartQuantity: (id:number) => void
    removeFromCart:(id:number) => void
    clearCart: () => void
    cartQuantity: number
    cartItems: CartItem[]
}

const ShoppingCartContext = createContext({} as ShoppingCartContext)

export function useShoppingCart(){

    return useContext(ShoppingCartContext)
}

export function ShoppingCartProvider({ children }: ShoppingCartContextProviderProps){
const [cartItems, setCartItems] = useLocalStorage<CartItem[]>("shopping-cart", [])


const cartQuantity = cartItems.reduce((quantity, item)=> {
return item.quantity + quantity}, 0)

function getItemQuantity(id: number){
    return cartItems.find(item => item.id === id)?.quantity || 1
}

function increaseCartQuantity(id: number, size:string, color:string){
    if(size ==="XS" && color === "Purple"){
        id = 1
        console.log("1")
    }else if(size ==="S" && color === "Purple"){
        id = 2
        console.log("2")
    }else if(size ==="M" && color === "Purple"){
        id = 3
        console.log("3")
    }else if(size ==="L" && color === "Purple"){
        id = 4
        console.log("4")
    }else if(size ==="XL" && color === "Purple"){
        id = 5
        console.log("5")
    }else if(size ==="XS" && color === "Orange"){
        id = 6
        console.log("6")
    }else if(size ==="S" && color === "Orange"){
        id = 7
        console.log("7")
    }else if(size ==="M" && color === "Orange"){
        id = 8
        console.log("8")
    }else if(size ==="L" && color === "Orange"){
        id = 9
        console.log("9")
    }else if(size ==="XL" && color === "Orange"){
        id = 10
        console.log("10")
    }else if(size ==="XS" && color === "Green"){
        id = 11
        console.log("11")
    }else if(size ==="S" && color === "Green"){
        id = 12
        console.log("12")
    }else if(size ==="M" && color === "Green"){
        id = 13
        console.log("13")
    }else if(size ==="L" && color === "Green"){
        id = 14
        console.log("14")
    }else if(size ==="XL" && color === "Green"){
        id = 15
        console.log("15")
    }else if(size ==="XS" && color === "Cyan"){
        id = 16
        console.log("16")
    }else if(size ==="S" && color === "Cyan"){
        id = 17
        console.log("17")
    }else if(size ==="M" && color === "Cyan"){
        id = 18
        console.log("18")
    }else if(size === "L" && color === "Cyan"){
        id = 19
        console.log("19")
    }else if(size ==="XL" && color === "Cyan"){
        id = 20
        console.log("20")
    }
    
     setCartItems(currItems =>{
        if(currItems.find(item => item.id === id) == null){  
            console.log(id)    
            return [...currItems, {id, quantity: 1, size, color}]
          }else{
            return currItems.map(item =>{
                if(item.id === id){
                    return{...item, quantity: item.quantity + 1}
                }else{
                    return item
                }
            })
          }      
     })   
}

function decreaseCartQuantity(id: number){
     setCartItems(currItems =>{
        if(currItems.find(item=> item.id === id)?.quantity === 1){
            return currItems.filter(item => item.id !== id)
          }else{
            return currItems.map(item =>{
                if(item.id ===id){
                    return{...item, quantity: item.quantity - 1}
                }else{
                    return item
                }
            })
          }
     })
}

function removeFromCart(id: number){
    setCartItems(currItems => {
    return currItems.filter(item => item.id !== id)
    })
}

function clearCart(){
    for(let i = 0; i < cartItems.length; i++ ){
        removeFromCart(cartItems[i].id)
    }
}
    return(
<ShoppingCartContext.Provider 
value={{
    getItemQuantity, 
    increaseCartQuantity, 
    decreaseCartQuantity,
    removeFromCart,
    clearCart, 
    cartItems, 
    cartQuantity
    }}>
    {children}
</ShoppingCartContext.Provider>

    )
}