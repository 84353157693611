import React from 'react'
const Newblog = () => {
    return ( 
        <div className="Newblog"> 
        <p> Welcome to the New World

        </p>
            </div>

     );
}
 
export default Newblog;