import { useShoppingCart } from "./CartContext"
import products from "./data/product.json"
import CartTshirtimage from "./carttshirt"
import { FormatCurrency } from "./FormatCurrency"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import OrderTshirtimage from "./Ordertshirt"

type OrderItemProps = {
    id: number,
    quantity:number,
    color: string,
    size: string
}



const price = 20.50

export function OrderItem({ id, quantity, color, size }: OrderItemProps) {   
    const { removeFromCart, increaseCartQuantity, decreaseCartQuantity, cartItems } = useShoppingCart()

    
    return (
        <div className="Ordercartitemsection">
<div className="checkoutcartItemholder">
<div className="Cartitem">
        <OrderTshirtimage color={color}></OrderTshirtimage>
        <div className="cartiteminfo">
        <div className="orderitemtitle">Curves and Curses T-Shirt</div>  
        <div className="orderitemdescription">
        <div className="cartitemcolorandsizeholder">       
        <div className="cartitemcolor">{color}</div>
        <div className="orderitemsizeholder">
        <div className="cartitemsize">{size}</div>
        </div>
        <div className="orderitemprice"> {FormatCurrency(20.50)}</div>  
        </div>    
       
        </div>
        </div>        
    </div>
 

</div>
<div className="checkoutquantityincreaser">
    <div className="orderquantityholder">        
        <div className="itemquantitydisplay"> X {quantity}</div>
    </div> 
</div>
<div className="ordertotal" >  {FormatCurrency(quantity * price)}</div>
</div>
    )

}

/* */