import React, { Component, useState, useRef } from 'react'
import HorizontalScroll from 'react-scroll-horizontal'
import Canvas from './Canvas'
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import { useShoppingCart } from './CartContext';
import { FormatCurrency } from './FormatCurrency';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { OrderItem } from './Orderitem';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import emailjs from '@emailjs/browser';


const Checkoutpage = (props) => {
    const containervariants = {
        hidden : {opacity: 0, x: "100%"}, 
        visible : { opacity: 1, x: "0%",
        transition :{ease:"easeInOut", type: "spring",
        stiffness: 260, damping: 20} }, 
        exit:{ x: "-100%", transition:{ ease: "easeInOut"} }
       } 
       
const location = useLocation();
console.log(location.state.data);

const{
    getItemQuantity,
    increaseCartQuantity,
    decreaseCartQuantity,
    removeFromCart,
    clearCart,
    cartItems
  } =useShoppingCart()

  const amount = cartItems.reduce((total, cartitem)=>{

    return total + 1 * cartitem.quantity;
 
  }, 0 )
  const currency = "USD"
 

var description = " ";

const descriptor = (description) => {

  for(let i = 0; i < cartItems.length; i++){
    const itemcolor = cartItems[i].color;
    const itemsize = cartItems[i].size;
    const itemquantity = cartItems[i].quantity;    
    description +=  " Curves and Curses Tee. Size: " + itemsize + " Color: " + itemcolor + " Quantity: " + itemquantity + ", " 
   
}
return description
}
descriptor(description);


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


 
const sendEmail = (description,total) => {

  var templateParams = {

    name: location.state.data.Name,
    email: location.state.data.Email,
    Address1: location.state.data.Address1,
    Address2: location.state.data.Address2,
    City: location.state.data.City,
    PostalCode: location.state.data.PostalCode,
    USstate: location.state.data.USstate,
    Phone: location.state.data.Phone,
    Total: total,
    productdescription: description
    
   };

  emailjs.send(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_TEMPLATE_SERVICE_ID2, templateParams, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
};


   return (
<motion.div className="maincontainer" variants={containervariants} initial="hidden" animate="visible" exit="exit">
{

show ?
<div className="purchasemodalbackdrop">
  <div className="purchasemodal">
    <div className="purchasemodaltitle">Thank you ! Thank You! Thank You!</div>
    <div className="purchasethankyounote"> OH EM GEEEEE! <br></br> You bought one of my quirky designs! <br></br> From this day forth you have my love forever and ever!</div>
      <div className="purchasemodalbuttonholder">
      <Link to={"/"}><button className="purchasemodalbutton" onClick={handleClose}> OK </button></Link>
     </div>
     
     </div>
    
  </div>
  : null  
} 

<div className="shippingmain">
<div className="orderinformationholder finalcheckout">
<div className="ordersummaryproductsectiontitle">
Order Summary
</div> 
<div className="orderdetailsholder">
<table className="Yourorder">
  <tr>
<td>
</td>

</tr>
  {cartItems.map(item=>{
    return <tr>
      <td>
        <OrderItem key={item.id} id={item.id} quantity={item.quantity} color={item.color} size={item.size}></OrderItem></td>   
      </tr>
  }
    )}    
<tr>
  <td colSpan={3}>
    
  </td>
</tr>
</table>

</div>
<div className="ordertotalcalculated">
  <div  className="totalproductsectiontitle">Total</div>
 <div className="totalproductsectiontitle">{FormatCurrency(cartItems.reduce((total, cartitem)=>{

   return total + 20.50 * cartitem.quantity;

 }, 0 ))

}</div>
</div>
</div> 
<div className="checkoutinfocontentholder finalcheckoutdetails">
    <div className="contactustitle checkoutshippinginformationtitle">
        Shipping information
    </div>
    <div className="paymentformholder">
      <div className="checkoutfieldsection">
        <div className="checkoutfieldholder">
        <label className = "formquestionlabel"  htmlFor="">Name</label>
       <div className="checkoutNameholder">{location.state.data.Name}</div>
       </div>
       <div className="checkoutfieldholder">
        <label className = "formquestionlabel"  htmlFor="">Email</label>
        <div className="checkoutNameholder">{location.state.data.Email}</div>
        </div>
        </div>
        <div className="checkoutfieldsection">
        <div className="checkoutfieldholder">
        <label  className = "formquestionlabel" htmlFor="">Address 1</label>
        <div className="checkoutNameholder">{location.state.data.Address1}</div>
        </div>
        <div className="checkoutfieldholder">
        <label className = "formquestionlabel"  htmlFor="">Address 2</label>
        <div className="checkoutNameholder">{location.state.data.Address2}</div>
        </div>
        </div>
        <div className="checkoutfieldsection">
        <div className="checkoutfieldholder">
        <label className="checkoutPostallabel" htmlFor="">Postal Code</label>
        <div className="checkoutNameholder">{location.state.data.PostalCode}</div>
        </div>
        <div className="checkoutfieldholder">
        <label className="checkoutcitylabel" htmlFor="">City</label>
        <div className="checkoutNameholder">{location.state.data.City}</div>
        </div>
        </div>
        <div className="checkoutfieldsection">
        <div className="checkoutfieldholder">
        <label className = "formquestionlabel"  htmlFor="">State</label>
        <div className="checkoutNameholder">{location.state.data.USstate}</div>
        </div>
        <div className="checkoutfieldholder">
        <label className = "formquestionlabel"  htmlFor="">Phone</label>
        <div className="checkoutNameholder">{location.state.data.Phone}</div>
        </div>
        </div>   
        </div>
        <div className="paypalbuttonholders"> 
        <div className="checkoutcontactustitle">
        Payment
    </div>
<PayPalScriptProvider options={{"client-id":"AbwDNS_28rxxSnhAP21wHRBeaRKMG4Ptcy0adY2aGKu05a7ndbp-ck-UbGRch1rEPBg70_LourNAwaS5&disable-funding=credit","vault":"true"}}>
    <PayPalButtons 
    createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                { 
                                  
                                  description: descriptor(description),
                                   amount: {
                                        currency_code: currency,
                                        value: amount,
                                    },
                                },
                            ],
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
    }}
    onApprove={function (data, actions) {
        return actions.order.capture().then(function (details) {         
          handleShow()
          sendEmail(details.purchase_units[0].description, details.purchase_units[0].amount.value)
          console.log(details.purchase_units[0].amount.value)             
          console.log(details.purchase_units[0].description)
          clearCart()           
        });
    }}
    style = {{
      width:10

    }}
    >

                </PayPalButtons>
</PayPalScriptProvider>

</div>

</div>

</div>
</motion.div>
    );
}
 
export default Checkoutpage;