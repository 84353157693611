import React from 'react'
import { useRef } from "react";
import starwars from './images/May the fourth.png';
import trans from './images/trans.png';
import halloween from './images/pumpkin1.png';
import scream from './images/scream.png';
import bikini from './images/magic bikkin1i.png';
import tara from './images/tara1.png';
import angels from './images/angels (1).png';
import space from './images/space.jpg';


export function ContactOndraw() {
const CanvasRef = useRef(null);

class Ball{
    constructor(effect){
        this.effect = effect;
        this.x = this.effect.width * 0.5;
        this.y = this.effect.height * 0.5;
        this.radius = Math.random() * 200;
        this.speedX = Math.random() - 0.5;
        this.speedY = Math.random() - 0.5;
    }
    update(){
        if(this.x < this.radius || this.x > this.effect.width - this.radius) this.speedX *= -1;
        if(this.y < this.radius || this.y > this.effect.width - this.radius) this.speedY *= -1;
        this.x += this.speedX;
        this.y += this.speedY;
    }
    draw(context){
       context.beginPath();
       context.arc(this.x, this.y, this.radius, 0,Math.PI* 2);
       context.fill();     

    }

}

class MetaBallEffect{
    constructor(width, height){
        this.width = width;
        this.height = height;
        this.metaBallsArray = [];
    }
    init(numberOfBalls){
            for(let i=0; i < numberOfBalls; i++){
                this.metaBallsArray.push(new Ball(this));
            }
    }
    update(){
            this.metaBallsArray.forEach(metaball => metaball.update());
    }
    draw(context){
        this.metaBallsArray.forEach(metaball => metaball.draw(context));
    }

}

function setContactCanvasRef(ref){
    if(!ref)return;
    CanvasRef.current = ref;
    const ctx = CanvasRef.current.getContext("2d");     
    const effect = new MetaBallEffect(CanvasRef.current.width,CanvasRef.current.height);
    console.log(CanvasRef.current.width);
    effect.init(50);
    const img = new Image();
    if(CanvasRef.current.id === "starwars"){
    img.src = starwars;
    }
    else if(CanvasRef.current.id === "trans"){
        img.src = trans;
    }
    else if(CanvasRef.current.id === "halloween"){
        img.src = halloween;
    } 
    else if(CanvasRef.current.id === "scream"){
        img.src = scream;
    } 
    else if(CanvasRef.current.id === "bikini"){
        img.src = bikini;
    }     
    else if(CanvasRef.current.id === "tara"){
        img.src = tara;
    }     
    else if(CanvasRef.current.id === "angels"){
        img.src = angels;
    }
    else if(CanvasRef.current.id === "space"){
        img.src = space;
    }     
    img.onload = () => {
      let w =  CanvasRef.current.width;
      let nw = img.naturalWidth;
      let nh =img.naturalHeight;
      let aspectratio = nw/nh;
      let h = w / aspectratio;
       w = h / aspectratio;
       img.height = CanvasRef.current.height * 2;
       img.width = CanvasRef.current.width * 2;
       console.log(w, h)
      CanvasRef.current.height = h;
      const pattern = ctx.createPattern(img, 'no-repeat');
      ctx.fillStyle = pattern;
      ctx.fillRect(0, 0, CanvasRef.current.width, CanvasRef.current.height);
      
    };      
    function animate(){
    ctx.clearRect(0,0,CanvasRef.current.width,CanvasRef.current.height);    
    effect.update();
    effect.draw(ctx);
    requestAnimationFrame(animate);    
    }
    animate();
    
    
    const homenav = CanvasRef.current.parentElement.parentElement.parentElement.parentElement.parentElement.firstChild.getElementsByClassName("links")[0].firstChild;     
    homenav.addEventListener("click", function(){
        console.log(homenav);
        while(effect.metaBallsArray.length != 0){   
            effect.metaBallsArray.pop();                        
    }                                       
         
        })
}
 
//create a function i.e. metaballs drawing to be initialized on the reference 
return setContactCanvasRef;
}