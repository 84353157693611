import React from 'react'
import { Ondraw } from "./Ondraw";


const Canvas = (props) => {
    const id = props.id;
    const width = props.width;
    const height = props.height;
    

const setCanvasRef = Ondraw();

    return ( 
        <canvas
        className='Homecanvas'
        id = {id} 
        width={width} 
        height={height}        
        ref = {setCanvasRef}
        >


        </canvas>
     );
}
 
export default Canvas;