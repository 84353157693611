import { useRef } from "react";
import React from 'react'
import starwars from './images/May the fourth.png';
import trans from './images/trans.png';
import halloween from './images/pumpkin1.png';
import scream from './images/scream.png';
import bikini from './images/magic bikkin1i.png';
import tara from './images/tara1.png';
import angels from './images/angels (1).png';
import dark from './images/space45.jpg';


export function NavOndraw() {
const NavCanvasRef = useRef(null);

class Ball{
    constructor(effect){
        this.effect = effect;
        this.x = this.effect.width * 0.5;
        this.y = this.effect.height * 0.5;
        this.radius = Math.random() * 100;
        this.speedX = Math.random() - 0.5;
        this.speedY = Math.random() - 0.5;
    }
    update(){
        if(this.x < this.radius || this.x > this.effect.width - this.radius) this.speedX *= -1;
        if(this.y < this.radius || this.y > this.effect.width - this.radius) this.speedY *= -1;
        this.x += this.speedX;
        this.y += this.speedY;
    }
    draw(context){
       context.beginPath();
       context.arc(this.x, this.y, this.radius, 0,Math.PI* 2);
       context.fill();     

    }

}

class MetaBallEffect{
    constructor(width, height){
        this.width = width;
        this.height = height;
        this.metaBallsArray = [];
    }
    init(numberOfBalls){
            for(let i=0; i < numberOfBalls; i++){
                this.metaBallsArray.push(new Ball(this));
            }
    }
    update(){
            this.metaBallsArray.forEach(metaball => metaball.update());
    }
    draw(context){
        this.metaBallsArray.forEach(metaball => metaball.draw(context));
    }

}

function setNavCanvasRef(ref){
    if(!ref)return;
    NavCanvasRef.current = ref;
    const ctx = NavCanvasRef.current.getContext("2d");    
    const effect = new MetaBallEffect(NavCanvasRef.current.width,NavCanvasRef.current.height);
    
    effect.init(20);
    const img = new Image();
    if(NavCanvasRef.current.id === "starwars"){
    img.src = starwars;
    }
    else if(NavCanvasRef.current.id === "trans"){
        img.src = trans;
    }
    else if(NavCanvasRef.current.id === "halloween"){
        img.src = halloween;
    }
    else if(NavCanvasRef.current.id === "scream"){
        img.src = scream;
    }
    else if(NavCanvasRef.current.id === "bikini"){
        img.src = bikini;
    }
    else if(NavCanvasRef.current.id === "tara"){
        img.src = tara;
    }     
    else if(NavCanvasRef.current.id === "angels"){
        img.src = angels;
    }
    else if(NavCanvasRef.current.id === "dark"){
        img.src = dark;
    }                                 
    img.onload = () => {
      let w =  NavCanvasRef.current.width;
      let nw = img.naturalWidth;
      let nh =img.naturalHeight;
      let aspectratio = nw/nh;
      let h = w / aspectratio;
       w = h / aspectratio;
       img.height = h;
       img.width = w;
      
      NavCanvasRef.current.height = h;
      const pattern = ctx.createPattern(img, 'no-repeat');
      ctx.fillStyle = pattern;
      ctx.fillRect(0, 0, NavCanvasRef.current.width, NavCanvasRef.current.height);
    };      
    function animate(){
    ctx.clearRect(0,0,NavCanvasRef.current.width,NavCanvasRef.current.height);    
    effect.update();
    effect.draw(ctx);
    requestAnimationFrame(animate);
    
    }
    animate();
    NavCanvasRef.current.addEventListener("mouseenter", function(){
                          
            while(effect.metaBallsArray.length <= 50){
            effect.metaBallsArray.push(new Ball(this));
            setTimeout(2000);           
                }                 
               
        NavCanvasRef.current.addEventListener("mouseleave", function(){
        while(effect.metaBallsArray.length >= 30){   
                effect.metaBallsArray.pop();
                setTimeout(2000)                
        } 
        
        /*const basepath = NavCanvasRef.current.parentNode.nextSibling.firstChild.firstChild.firstChild.firstChild.firstChild.firstChild;*/
       
        })        
    })

    NavCanvasRef.current.addEventListener("click", function(){
                      
     
    })

    const navlinks = NavCanvasRef.current.nextSibling.nextSibling.getElementsByClassName("dropdownmenulink");
    const length = navlinks.length  
    for (let i = 0; i < length; i++){
    navlinks[i].querySelector("a").addEventListener("click", function(){
        
        while(effect.metaBallsArray.length != 0){         
            effect.metaBallsArray.pop();                        
    }
    
            })
    }   
        
}
 
//create a function i.e. metaballs drawing to be initialized on the reference 
return setNavCanvasRef;
}