import { useRef } from "react";
import React from 'react'
import starwars from './images/May the fourth.png';
import trans from './images/trans.png';
import halloween from './images/pumpkin1.png';
import scream from './images/scream.png';
import bikini from './images/magic bikkin1i.png';
import tara from './images/JUSTICE FOR TARA.png';
import angels from './images/UGLY ANGELS.png';

export function Ondraw() {
const CanvasRef = useRef(null);

class Ball{
    constructor(effect){
        this.effect = effect;
        this.x = this.effect.width * 0.5;
        this.y = this.effect.height * 0.5;
        this.radius = Math.random() * 100;
        this.speedX = Math.random() - 0.5;
        this.speedY = Math.random() - 0.5;
    }
    update(){
        if(this.x < this.radius || this.x > this.effect.width - this.radius) this.speedX *= -1;
        if(this.y < this.radius || this.y > this.effect.width - this.radius) this.speedY *= -1;
        this.x += this.speedX;
        this.y += this.speedY;
    }
    draw(context){
       context.beginPath();
       context.arc(this.x, this.y, this.radius, 0,Math.PI* 2);
       context.fill();     

    }

}

class MetaBallEffect{
    constructor(width, height){
        this.width = width;
        this.height = height;
        this.metaBallsArray = [];
    }
    init(numberOfBalls){
            for(let i=0; i < numberOfBalls; i++){
                this.metaBallsArray.push(new Ball(this));
            }
    }
    update(){
            this.metaBallsArray.forEach(metaball => metaball.update());
    }
    draw(context){
        this.metaBallsArray.forEach(metaball => metaball.draw(context));
    }

}

function setCanvasRef(ref){
    if(!ref)return;
    CanvasRef.current = ref;
    const ctx = CanvasRef.current.getContext("2d");    
    const effect = new MetaBallEffect(CanvasRef.current.width,CanvasRef.current.height);
    console.log(CanvasRef.current.width);
    effect.init(20);
    const img = new Image();
    if(CanvasRef.current.id === "starwars"){
    img.src = starwars;
    }
    else if(CanvasRef.current.id === "trans"){
        img.src = trans;
    }
    else if(CanvasRef.current.id === "halloween"){
        img.src = halloween;
    }
    else if(CanvasRef.current.id === "scream"){
        img.src = scream;
    }
    else if(CanvasRef.current.id === "bikini"){
        img.src = bikini;
    }
    else if(CanvasRef.current.id === "tara"){
        img.src = tara;
    }     
    else if(CanvasRef.current.id === "angels"){
        img.src = angels;
    }                    
    img.onload = () => {
      let w =  CanvasRef.current.width;
      let nw = img.naturalWidth;
      let nh =img.naturalHeight;
      let aspectratio = nw/nh;
      let h = w / aspectratio;
       w = h / aspectratio;
       img.height = h;
       img.width = w;
       console.log(w, h)
      CanvasRef.current.height = h;
      const pattern = ctx.createPattern(img, 'no-repeat');
      ctx.fillStyle = pattern;
      ctx.fillRect(0, 0, CanvasRef.current.width, CanvasRef.current.height);
    };      
    function animate(){
    ctx.clearRect(0,0,CanvasRef.current.width,CanvasRef.current.height);    
    effect.update();
    effect.draw(ctx);
    requestAnimationFrame(animate);
    
    }
    animate();
    CanvasRef.current.addEventListener("mouseenter", function(){
                          
            while(effect.metaBallsArray.length <= 50){
            effect.metaBallsArray.push(new Ball(this));
            setTimeout(2000);           
                }                 
               
        CanvasRef.current.addEventListener("mouseleave", function(){
        while(effect.metaBallsArray.length >= 30){   
                effect.metaBallsArray.pop();
                setTimeout(2000)                
        }                     
                     
        })        
    })

    CanvasRef.current.addEventListener("click", function(){
    console.log("cick");                    
     
    })

    const worknav = CanvasRef.current.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.firstChild.getElementsByClassName("links")[0].firstChild.nextSibling;
    worknav.addEventListener("click", function(){
        console.log("clicked");
        while(effect.metaBallsArray.length != 0){   
            effect.metaBallsArray.pop();                        
    }                                       
         
        })   
        const homenav = CanvasRef.current.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.firstChild.getElementsByClassName("links")[0].firstChild;
        homenav.addEventListener("click", function(){
            console.log("clicked");
            while(effect.metaBallsArray.length != 0){   
                effect.metaBallsArray.pop();                        
        }                                       
             
            })
            
            const hamburgernav = CanvasRef.current.parentElement.parentElement.parentElement.parentElement.parentElement.firstChild.lastChild.getElementsByClassName("dropdownmenulink");     
            const length = hamburgernav.length  
            for (let i = 0; i < length; i++){
            hamburgernav[i].querySelector("a").addEventListener("click", function(){
                
                while(effect.metaBallsArray.length != 0){         
                    effect.metaBallsArray.pop();                        
            }
                
                })
            }
            
            const navlinks = CanvasRef.current.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("dropdownmenulink");
            console.log(navlinks)
            const navlinkslength = navlinks.length  
            for (let i = 0; i < navlinkslength; i++){
            navlinks[i].querySelector("a").addEventListener("click", function(){                                        
            
            while(effect.metaBallsArray.length != 0){         
                effect.metaBallsArray.pop();                        
        }
                })
            }   


}
 
//create a function i.e. metaballs drawing to be initialized on the reference 
return setCanvasRef;
}