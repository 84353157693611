import { useShoppingCart } from "./CartContext"
import products from "./data/product.json"
import CartTshirtimage from "./carttshirt"
import { FormatCurrency } from "./FormatCurrency"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

type CartItemProps = {
    id: number,
    quantity:number,
    color: string,
    size: string
}



const price = 20.50

export function CartItem({ id, quantity, color, size }: CartItemProps) {   
    const { removeFromCart, increaseCartQuantity, decreaseCartQuantity, cartItems } = useShoppingCart()

    
    return (
        <div className="cartitemsection">
<div className="cartItemholder">
<div className="Cartitem">
        <CartTshirtimage color={color}></CartTshirtimage>
        <div className="cartiteminfo">
        <div className="cartitemtitle">Curves and Curses T-Shirt</div>  
        <div className="cartitemdescription">
        <div className="cartitemcolorandsizeholder">       
        <div className="cartitemcolor">{color}</div>
        <div className="cartitemsizeholder">
        <div className="cartitemsize">{size}</div>
        </div>  
        </div>       
        <div className="cartitemprice"> {FormatCurrency(20.50)}</div>
        </div>
        </div>        
    </div>
 

</div>
<div className="quantityincreaser">
    <div className="quantitytitle">Quantity</div>
    <div className="addandreducebuttonholder">
        <button className="reduceitembutton addreducebutton" onClick={()=>decreaseCartQuantity(id)}>-</button>
        <div className="itemquantitydisplay">{quantity}</div>      
        <button className="additembutton addreducebutton" onClick={()=>increaseCartQuantity(id,size,color)}>+</button>
    </div> 
</div>
<div className="removeitembuttonholder">
    <button className="removebutton" onClick={()=>removeFromCart(id)}> <FontAwesomeIcon className="Trashcan" icon={faTrash} style={{color: "#ffffff",}} /></button>
</div>
<div className="total" >  {FormatCurrency(quantity * price)}</div>
</div>
    )

}

/* */