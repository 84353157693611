function Tshirtimage(props) {
const color = props.color;
    if(color === "Purple"){
        
        return <img className="producttpagemainimg" src={require("./images/My project - 2023-05-17T162732.106.png").default} alt="" />

         }
         else if(color === "Orange"){
            
            return  <img className="producttpagemainimg" src={require("./images/My project - 2023-05-23T172552.107 (2) (2).png").default} alt="" />
         }else if (color === "Green"){
           
            return   <img className="producttpagemainimg" src={require("./images/My project - 2023-05-23T172647.729 (1) (1).png").default} alt="" />
         }else if (color === "Cyan"){
            
            return   <img className="producttpagemainimg" src={require("./images/My project - 2023-05-23T173048.898 (1) (1).png").default} alt="" />
        } else{
            return null
         }
}
 
export default Tshirtimage;