import React, { Component, useState, useRef } from 'react'
import HorizontalScroll from 'react-scroll-horizontal'
import ContactCanvas from './ContactCanvas'
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import emailjs from '@emailjs/browser';
import { Link } from 'react-router-dom';

const Contact = () => {
    console.log(process.env.REACT_APP_EMAIL_SERVICE_ID)
    const containervariants = {
        hidden : {opacity: 0, x: "100%"}, 
        visible : { opacity: 1, x: "0%",
        transition :{ease:"easeInOut", type: "spring",
        stiffness: 260, damping: 20} }, 
        exit:{ x: "-100%", transition:{ ease: "easeInOut"} }
        
       }
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        setShow(true)
        
        emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_TEMPLATE_SERVICE_ID, form.current, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };


      const [show, setShow] = useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

   return (
<motion.div className="maincontainer" variants={containervariants} initial="hidden" animate="visible" exit="exit">
{

show ?
<div className="purchasemodalbackdrop">
  <div className="purchasemodal">
    <div className="purchasemodaltitle">Thank you for submitting your Commission request!</div>
    <div className="purchasethankyounote"> I am super excited!<br></br> Checking my calendar right now. I'll reply to you soon!</div>
      <div className="purchasemodalbuttonholder">
      <Link to={"/"}><button className="purchasemodalbutton" onClick={handleClose}> OK </button></Link>
     </div>
     
     </div>
    
  </div>
  : null  
} 
<div className="newslidercontent">
<div className="contactuscontentholder">
    <div className="contactustitle">
        Contact Form
    </div>
    <ContactCanvas id={"space"} width={1000} height={1000}></ContactCanvas>  
    <div className="formholder">
        <form ref={form} onSubmit={sendEmail}>
        <label htmlFor="">Name</label>
        <input type="text" name="name" required />
        <label htmlFor="">Email</label>
        <input type="email" required name="email"/>
        <label htmlFor="">Type of Requirement</label>
        <div className="radiobutton"><input type="radio" value="Basic" name='requirementtype'/> Basic - Simple illustration in clean and detailed line art *No color</div>
        <div className="radiobutton"><input type="radio" value="Medium" name='requirementtype'/> Standard - Character/ Figure with detailed color and shading </div>
        <div className="radiobutton"><input type="radio" value="Full" name='requirementtype'/>  Premium - Character/ Figure and background/scene with detailed color and shading </div>     
        <label htmlFor="">What do you want your art to look like? </label>
        <select className="Optionselection" name="artstyle" id="">
            <option value="Minimalist">Minimalist</option>
            <option value="Cartoon">Cartoon</option>
            <option value="Anime/Manga">Anime/Manga</option>
            <option value="Cute/Chibi">Cute/Chibi</option>
            <option value="Tee shirt Design">Tee shirt Design</option>              
        </select>       
        <label htmlFor="">Description of Requirement</label>
        <textarea name="description"></textarea>
        <div className="outerbuttonholder">
        <div className="buttonholder1">
          <div className="buttoninner"></div>
        <input className='CTAbutton' type="submit" value="Submit Request"/>
        </div>
        </div>
        </form>
        </div>
         
</div>
</div>

</motion.div>
    );
}
 
export default Contact;