import React, { Component } from 'react'
import HorizontalScroll from 'react-scroll-horizontal'
import Canvas from './Canvas'
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";

const About = () => {
    const containervariants = {
        hidden : {opacity: 0, x: "100%"}, 
        visible : { opacity: 1, x: "0%",
        transition :{ease:"easeInOut", type: "spring",
        stiffness: 260, damping: 20} }, 
        exit:{ x: "-100%", transition:{ ease: "easeInOut"} }
       } 
   return (
<motion.div className="maincontainer" variants={containervariants} initial="hidden" animate="visible" exit="exit">
<div className="newslidercontent">
<div className="herotitleholdermerch">
    <div className="heroimage1">
   <img className="aboutimg" src={require("./images/Hiawatha art logo2.png").default} alt="" /> 
   </div>
</div>
<div className="aboutuscontentholder">
    <div className="aboutustitle">
        ABOUT US
    </div>
    <div className="aboutustext">
    I'm a quirky and innovative artist who specializes in creating unique, eye-catching designs that help brands stand out from the crowd.
    <br></br>
    <br></br>
    My art is a reflection of my playful and unconventional approach to life. I love to take familiar objects and concepts and turn them on their head, creating unexpected and often humorous results. I believe that art should be fun, engaging, and thought-provoking, and I strive to bring those qualities to everything I create.
    <br></br>
    <br></br>
    As a branding artist, I understand the importance of creating designs that capture the essence of a brand and connect with its target audience. I work closely with my clients to understand their vision and create artwork that reflects their unique personality and values. Whether it's a logo, a product label, or a marketing campaign, I'm always looking for ways to push the boundaries and create something that truly stands out.
    <br></br>
    <br></br>
    So if you're looking for an artist who can help your brand make a statement, look no further! I'm passionate about using my creativity and my unique style to help brands connect with their audience and create lasting impressions. Let's work together to create something truly memorable and unique!
    </div>
</div>
</div>

</motion.div>
    );
}
 
export default About;