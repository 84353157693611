import React, { Component } from 'react';
import WorkCanvas from './WorkCanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import { Link } from 'react-router-dom';



const Tara = () => {
     const containervariants = {
      hidden : {opacity: 0, x: "100%"}, 
      visible : { opacity: 1, x: "0%",
      transition :{ease:"easeInOut", type: "spring",
      stiffness: 260, damping: 20} }, 
      exit:{ x: "-100%", transition:{ ease: "easeInOut"} }
     } 
   return (
<motion.div className="workcontainer" variants={containervariants} initial="hidden" animate="visible" exit="exit">
  <div className="workcontentholder">
 <div className="overlaytextholder">
  <h2 className="Workmaintitle1"><span>'Justice for Tara' <br></br>Manga</span></h2>
  <p className= "Workmainpara"><span>Personal Project based on a sleuth story about a girl's murder</span></p>
  </div> 
<div className="workcanvasholder">
<WorkCanvas id={"tara1"} width={600} height={1000}></WorkCanvas>
</div>
</div>
<motion.div initial={{ opacity: 0, x: "-100%"}} animate = {{ opacity: 1, x: "0%" }} transition={{ delay: 2, ease:"easeInandOut"}} className="rightcontentsectiontara">
  <motion.div initial={{ opacity: 0 }} animate = {{ opacity: 1 }} transition={{ delay: 3 }} className="workimgtara1">
    <motion.img initial={{ opacity: 0 }} animate = {{ opacity: 1 }} transition={{ delay: 4 }} className="workimgtara taraeyes" src={require("./images/TARA EYES.png").default} alt="" />
    </motion.div>
  <div className="workimgtara2">
  <img className='workimgtara' src={require("./images/TARA MOUTH1.png").default} alt="" />
  </div>
  <div className="workimgtara3">
  <img className='workimgtara' src={require("./images/TARA BODY.png").default} alt="" />
  </div> 
  <div className="workimgtara4">
  <img className='workimgtara' src={require("./images/TARATITLE.png").default} alt="" />
  </div> 
  <div className="arrowsectionhalloween">
  <Link className="linknext" to="/Coffee">
  <img className="Arrowimages" src={require("./images/nextproject.png").default} alt="" />
  <img className="Arrowimages" src={require("./images/aroows.png").default} alt="" />
  </Link>
  </div>
</motion.div>

<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
    <filter id="gooeysvg">
      <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
      <feColorMatrix in="blur" mode="matrix"
        values=" 1 0 0 0 0
                 0 1 0 0 0
                 0 0 1 0 0
                 0 0 0 20 -7" result="gooeysvg" />
      <feComposite in="SourceGraphic" in2="gooeysvg" operator="atop"/>
    </filter>
  </defs>
</svg>
</motion.div>  
    );
}
 
export default Tara;