import React from 'react'
import { ContactOndraw } from "./ContactOndraw";


const ContactCanvas = (props) => {
    const id = props.id;
    const width = props.width;
    const height = props.height;    

const setContactCanvasRef = ContactOndraw();

    return ( 
        <canvas
        className="contactcanvas"
        id = {id}
        width={width} 
        height={height}                
        ref = {setContactCanvasRef}
        >
        </canvas>
     );
}
 
export default ContactCanvas;