import React, { Component } from 'react'
import HorizontalScroll from 'react-scroll-horizontal'
import Canvas from './Canvas'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

library.add(faInstagram)

const Home = () => {
    
   return (
<div className="maincontainer">
<div className="newslidercontent">
<HorizontalScroll reverseScroll ={true}>

<div className ="slider">
<div className="herotitleholder">
     <div className="titleholder">
        <h1>Qreative</h1>
        <br></br>
        <h1>Quirky</h1>
        <br></br>
        <h1>Design</h1>
        <br></br>
        </div>
        <div className="titleholdermobile">
        <h1>Qreative Quirky Design</h1>        
        </div>
        <div className="subtitleholder">
            <img className='subtitle' src={require("./images/subtitle.png").default} alt="" />
            <img className='mobilesubtitle' src={require("./images/subtitle1.png").default} alt="" />
            </div>
        <Link to="/Contact">
        <div className="buttonholder">
          <div className="buttoninner"></div>
          <button className='CTAbutton'>LET’S BE QREATIVE TOGETHER</button>
          </div>
          </Link>       
</div>
<div className="contentcanvasfirst">
<div className="overlapcontent">
<div className="descriptorholder">
<Link to="/Halloween"><h2 class="internaltitletext"><span>Halloween<br></br> Merch Collection</span></h2></Link>
 <h3 class="internalsubtitletext"><span>Merch</span></h3>
 </div> 
<Canvas id={"halloween"} width={300} height={300}></Canvas>
</div>
<div className="canvasholder1">
 <div className="descriptorholder">
 <Link to="/FightorDie"><h2 class="internaltitletext"><span>‘Fight or Die’ <br></br> Poster Series</span></h2></Link>   
 <h3 class="internalsubtitletext"><span>the way tshirts</span></h3>
  </div> 
<Canvas id={"starwars"} width={600} height={600}></Canvas>
</div>
</div>
<div className="secondcontentholder">
<div className="canvasholder2">
<div className="descriptorholder">
<Link to="/trans"><h2 class="internaltitletext"><span>'International Day Against Homophobia, Biphobia, <br></br> Lesbophobia and Transphobia'</span></h2></Link>
 <h3 class="internalsubtitletext"><span>Art work</span></h3>
 </div> 
<Canvas id={"trans"} width={600} height={600}></Canvas>
</div>
<div className="overlapundercontent">
<div className="descriptorholder2">
<Link to="/Coffee"><h2 class="internaltitletext"><span>'Coffee' Merch Collection</span></h2></Link>
 <h3 class="internalsubtitletext"><span>Merch</span></h3>
 </div> 
<Canvas id={"scream"} width={800} height={800}></Canvas>
</div>
<div className="overlapundercontent1">
<div className="descriptorholder3">
<Link to="/Merch"><h2 class="internaltitletext"><span>'Summer' Bikini Collection</span></h2></Link>
 <h3 class="internalsubtitletext"><span>Merch</span></h3>
 </div> 
<Canvas id={"bikini"} width={400} height={400}></Canvas>
</div>
<div className="overlapundercontent2">
<div className="descriptorholder4">
<Link to="/Tara"><h2 class="internaltitletext"><span>'Justice for Tara'</span></h2></Link>
 <h3 class="internalsubtitletext"><span>Manga</span></h3>
 </div>
 <Canvas id={"tara"} width={600} height={600}></Canvas> 
</div>
<div className="overlapundercontent3">
<div className="descriptorholder5">
 <h2 class="internaltitletext"><span>'Ugly Angels'</span></h2>
 <h3 class="internalsubtitletext"><span>Boardgame concept</span></h3>
 </div>
 <Canvas id={"angels"} width={800} height={800}></Canvas> 
</div>
<div className="overlapundercontent4">
<div className="sociallinks">
  <div className="socialmedialinkholder">
  <Link className="instalink" to="https://www.instagram.com/hiawatha_art/" >
    <div className="instagramlink">
    <FontAwesomeIcon className="instaicon" icon={faInstagram} style={{color: "#000"}} />
        </div>
        </Link>
  </div>
</div>
<div className="bewlogo">
<Link className="bewlink" to="https://bewcollective.com/">
  <div className="bewlogoholder">
    <img src={require("./images/BEW Collective2white.png").default} alt="" className="bewlogoimg1" />
    <img src={require("./images/BEW Collective3.png").default} alt="" className="bewlogoimg2" />
  </div>
  </Link>
  </div>  
</div>
</div>

</div>
<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
    <filter id="gooeysvg">
      <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
      <feColorMatrix in="blur" mode="matrix"
        values=" 1 0 0 0 0
                 0 1 0 0 0
                 0 0 1 0 0
                 0 0 0 20 -7" result="gooeysvg" />
      <feComposite in="SourceGraphic" in2="gooeysvg" operator="atop"/>
    </filter>
  </defs>
</svg>

</HorizontalScroll>
</div>
</div>
    );
}
 
export default Home;