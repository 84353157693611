import React, { Component } from 'react';
import WorkCanvas from './WorkCanvas';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/free-solid-svg-icons'
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import { Link } from 'react-router-dom';

const Halloween = () => {
  const containervariants = {
    hidden : {opacity: 0, x: "100%"}, 
    visible : { opacity: 1, x: "0%",
    transition :{ease:"easeInOut", type: "spring",
    stiffness: 260, damping: 20} }, 
    exit:{ x: "-100%", transition:{ ease: "easeInOut"} }
   } 
   return (

<motion.div className="workcontainer" variants={containervariants} initial="hidden" animate="visible" exit="exit">
  <div className="workcontentholder">
 <div className="overlaytextholder">
  <h2 className="Workmaintitle1"><span>'Trick or Treat' <br></br>T-shirt Series</span></h2>
  <p className= "Workmainpara"><span>Artwork for special 'Halloween' Merch</span></p>
  </div> 
<div className="workcanvasholder">
<WorkCanvas id={"halloween1"} width={600} height={1000}></WorkCanvas>
</div>
</div>
<div className="rightcontentsectiontrans halloweenrightsection">
  <div className="workimghalloween1">
    <img className="workimghalloween" src={require("./images/halloween.png").default} alt="" />
    </div>
  <div className="workimghalloween2">
  <img className='workimghalloween' src={require("./images/halloween1.png").default} alt="" />
  </div>
  <div className="workimghalloween3">
  <img className='workimghalloween' src={require("./images/My project (83) 1.png").default} alt="" />
  <Link to="/Merch"><div className="externalicon"><FontAwesomeIcon icon={faExternalLink} style={{color: "#ffffff",}} /></div></Link>
  </div>
  <div className="workimghalloween4">
  <img className='workimghalloween' src={require("./images/Halloween2.png").default} alt="" />
  </div> 
  <div className="workimghalloween5">
  <img className='workimghalloween' src={require("./images/halloween3.png").default} alt="" />
  </div> 
  <div className="workimghalloween6">
  <img className='workimghalloween' src={require("./images/ToT.png").default} alt="" />
  <Link to="/Merch"><div className="externalicon"><FontAwesomeIcon icon={faExternalLink} style={{color: "#ffffff",}} /></div></Link>
  </div>  
  <div className="arrowsectionhalloween">
  <Link className="linknext" to="/FightorDie">
  <img className="Arrowimages" src={require("./images/nextproject.png").default} alt="" />
  <img className="Arrowimages" src={require("./images/aroows.png").default} alt="" />
  </Link>
  </div>
</div>

<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
    <filter id="gooeysvg">
      <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
      <feColorMatrix in="blur" mode="matrix"
        values=" 1 0 0 0 0
                 0 1 0 0 0
                 0 0 1 0 0
                 0 0 0 20 -7" result="gooeysvg" />
      <feComposite in="SourceGraphic" in2="gooeysvg" operator="atop"/>
    </filter>
  </defs>
</svg>
</motion.div>  

    );
}
 
export default Halloween;