import React, { Component } from 'react'
import HorizontalScroll from 'react-scroll-horizontal'
import Canvas from './Canvas'
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import { Link } from 'react-router-dom';

const Merch = () => {
    const containervariants = {
        hidden : {opacity: 0, x: "100%"}, 
        visible : { opacity: 1, x: "0%",
        transition :{ease:"easeInOut", type: "spring",
        stiffness: 260, damping: 20} }, 
        exit:{ x: "-100%", transition:{ ease: "easeInOut"} }
       } 
   return (
<motion.div className="maincontainer" variants={containervariants} initial="hidden" animate="visible" exit="exit">
<div className="newslidercontent">
<HorizontalScroll reverseScroll ={true}>

<div className ="merchslider">
<div className="herotitleholdermerch">
     <div className="titleholder">
        <h1>Merch</h1>
        <br></br>         
        </div>
        <div className="subtitleholder">
            <p className='subtitle'> Are you ready to add a splash of whimsy to your wardrobe? We've got a menagerie of fun and funky designs that are bursting with personality.</p>
            </div>
            <div className="titleholdermobile">
        <h1>Merch</h1>
        <br></br>         
        </div>
        <div className="subtitleholdermobile">
            <p className='subtitlemobile'> <br></br> Are you ready to add a splash of whimsy to your wardrobe? <br></br> We've got a menagerie of fun and funky designs that are bursting with personality.</p>
            </div>              
</div>
<div className="merchcontainer">
<Link to="/Productpage">
    <div className="merchitem">
        <div className="merchimg item1">
        <img src={require("./images/My project (83) 1.png").default} alt="testinimage1" />        
        </div>
        <div className="merchtitle">Curves and Curses Merch</div>
        <div className="merchdescription"> From spookily soft sweatshirts to chillingly comfy tees, you're sure to find the perfect fit to wear out on Halloween! 

</div>    
    </div>
    </Link>
    <div className="merchitem item2">
        <div className="merchimg">
        <img src={require("./images/coffee hoodie.png").default} alt="" />
        <img className="soldout" src={require("./images/soldout.png").default} alt="" />
        </div>
        <div className="merchtitle">Scream Coffee Merch</div>
        <div className="merchdescription">For all the coffee junkies who would die without coffee. Represent your addiction in style!</div>
    </div>
    <div className="merchitem item3">
        <div className="merchimg">
        <img src={require("./images/My project (83) 3 (1).png").default} alt="" />
        <img className="soldout" src={require("./images/soldout.png").default} alt="" />
        </div>
        <div className="merchtitle">Magic Bikini</div>
        <div className="merchdescription">The magic bikini will have you casting a spell on any and all eyes that happen to  glance your way.</div>
    </div>
    <div className="merchitem item2">
        <div className="merchimg">
        <img src={require("./images/My project (83) 1 (1).png").default} alt="" />
        <img className="soldout" src={require("./images/soldout.png").default} alt="" />
        </div>
        <div className="merchtitle">Trick O' Treat Merch</div>
        <div className="merchdescription">CAUTION: People who see you wearing our Trick O' Treat collection will be haunted by captivating visions of you forever.</div>
    </div>
    <div className="merchitem item3">
        <div className="merchimg">
        <img src={require("./images/My project (83) 2 (2).png").default} alt="" />
        <img className="soldout" src={require("./images/soldout.png").default} alt="" />
        </div>
        <div className="merchtitle">Drink Coffee, Make Waves Merch</div>
        <div className="merchdescription">Are you a coffee lover who's not afraid to make some waves? Our "Drink Coffee and Make Waves" merch is the perfect addition to your wardrobe!</div>
    </div>
    <div className="merchitem item2">
        <div className="merchimg">
        <img src={require("./images/My project (83) 2 (3).png").default} alt="" />
        <img className="soldout" src={require("./images/soldout.png").default} alt="" />
        </div>
        <div className="merchtitle">Cool Tiger Bikini</div>
        <div className="merchdescription">Unleash your cool inner tigress at the beach! Be bold. Be confident. Be fierce. </div>
    </div>
    <div className="merchitem item3">
        <div className="merchimg">
        <img src={require("./images/My project (83) 1 (3).png").default} alt="" />
        <img className="soldout" src={require("./images/soldout.png").default} alt="" />
        </div>
        <div className="merchtitle">'Mona Lisa' Coffee Merch</div>
        <div className="merchdescription">A statement every Coffee Enthusiast would agree with and a fit that every coffee enthusiast needs.</div>
    </div>
    <div className="merchitem item2">
        <div className="merchimg">
        <img src={require("./images/My project (83) 2 (4).png").default} alt="" />
        <img className="soldout" src={require("./images/soldout.png").default} alt="" />
        </div>
        <div className="merchtitle">Chill Pill Bikini</div>
        <div className="merchdescription">The perfect Chill Pill to get rid of your Summertime Sadness!</div>
    </div>
</div>
</div>

</HorizontalScroll>
</div>
</motion.div>
    );
}
 
export default Merch;