import React from 'react'
import { useRef } from "react";
import starwars1 from './images/May the fourth.png';
import trans1 from './images/trans.png';
import halloween1 from './images/pumpkin1.png';
import scream1 from './images/scream.png';
import bikini1 from './images/magic bikkin1i.png';
import tara1 from './images/JUSTICE FOR TARA.png';
import angels1 from './images/UGLY ANGELS.png';

export function WorkOndraw() {
const CanvasRef = useRef(null);

class Ball{
    constructor(effect){
        this.effect = effect;
        this.x = this.effect.width * 0.5;
        this.y = this.effect.height * 0.5;
        this.radius = Math.random() * 200;
        this.speedX = Math.random() - 0.5;
        this.speedY = Math.random() - 0.5;
    }
    update(){
        if(this.x < this.radius || this.x > this.effect.width - this.radius) this.speedX *= -1;
        if(this.y < this.radius || this.y > this.effect.width - this.radius) this.speedY *= -1;
        this.x += this.speedX;
        this.y += this.speedY;
    }
    draw(context){
       context.beginPath();
       context.arc(this.x, this.y, this.radius, 0,Math.PI* 2);
       context.fill();     

    }

}

class MetaBallEffect{
    constructor(width, height){
        this.width = width;
        this.height = height;
        this.metaBallsArray = [];
    }
    init(numberOfBalls){
            for(let i=0; i < numberOfBalls; i++){
                this.metaBallsArray.push(new Ball(this));
            }
    }
    update(){
            this.metaBallsArray.forEach(metaball => metaball.update());
    }
    draw(context){
        this.metaBallsArray.forEach(metaball => metaball.draw(context));
    }

}

function setWorkCanvasRef(ref){
    if(!ref)return;
    CanvasRef.current = ref;
    const ctx = CanvasRef.current.getContext("2d");     
    const effect = new MetaBallEffect(CanvasRef.current.width,CanvasRef.current.height);
    console.log(CanvasRef.current.width);
    effect.init(50);
    const img = new Image();
    if(CanvasRef.current.id === "starwars1"){
    img.src = starwars1;
    }
    else if(CanvasRef.current.id === "trans1"){
        img.src = trans1;
    }
    else if(CanvasRef.current.id === "halloween1"){
        img.src = halloween1;
    } 
    else if(CanvasRef.current.id === "scream1"){
        img.src = scream1;
    } 
    else if(CanvasRef.current.id === "bikini1"){
        img.src = bikini1;
    }     
    else if(CanvasRef.current.id === "tara1"){
        img.src = tara1;
    }     
    else if(CanvasRef.current.id === "angels1"){
        img.src = angels1;
    }     

    img.onload = () => {
      let w =  CanvasRef.current.width;
      let nw = img.naturalWidth;
      let nh =img.naturalHeight;
      let aspectratio = nw/nh;
      let h = w / aspectratio;
       w = h / aspectratio;
       img.height = CanvasRef.current.height * 2;
       img.width = CanvasRef.current.width * 2;
       console.log(w, h)
      CanvasRef.current.height = h;
      const pattern = ctx.createPattern(img, 'no-repeat');
      ctx.fillStyle = pattern;
      ctx.fillRect(0, 0, CanvasRef.current.width, CanvasRef.current.height);
      
    };      
    function animate(){
    ctx.clearRect(0,0,CanvasRef.current.width,CanvasRef.current.height);    
    effect.update();
    effect.draw(ctx);
    requestAnimationFrame(animate);    
    }
    animate();
    
    const arrownode = CanvasRef.current.parentElement.parentElement.nextSibling.lastChild.firstChild;   
    arrownode.addEventListener("click", function(){
        console.log("clicked");
        while(effect.metaBallsArray.length != 0){   
            effect.metaBallsArray.pop();                        
    }                                       
         
        })
    const homenav = CanvasRef.current.parentElement.parentElement.parentElement.parentElement.parentElement.firstChild.getElementsByClassName("links")[0].firstChild;     
    homenav.addEventListener("click", function(){
        console.log("clicked");
        while(effect.metaBallsArray.length != 0){   
            effect.metaBallsArray.pop();                        
    }                                       
         
        })
        const hamburgernav = CanvasRef.current.parentElement.parentElement.parentElement.parentElement.parentElement.firstChild.lastChild.getElementsByClassName("dropdownmenulink");     
        const length = hamburgernav.length  
        for (let i = 0; i < length; i++){
        hamburgernav[i].querySelector("a").addEventListener("click", function(){
            
            while(effect.metaBallsArray.length != 0){         
                effect.metaBallsArray.pop();                        
        }
            
            })
        }
        
        const navlinks = CanvasRef.current.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.getElementsByClassName("dropdownmenulink");
        console.log(navlinks)
        const navlinkslength = navlinks.length  
        for (let i = 0; i < navlinkslength; i++){
        navlinks[i].querySelector("a").addEventListener("click", function(){                                        
        
        while(effect.metaBallsArray.length != 0){         
            effect.metaBallsArray.pop();                        
    }
            })
        }   
        
    
}
 
//create a function i.e. metaballs drawing to be initialized on the reference 
return setWorkCanvasRef;
}