import React from 'react'
import { NavOndraw } from "./NavOndraw";


const NavCanvas = (props) => {
    const id = props.id;
    const width = props.width;
    const height = props.height;
    

const setNavCanvasRef = NavOndraw();

    return ( 
        <canvas
        className="navcanvas"
        id = {id} 
        width={width} 
        height={height}        
        ref = {setNavCanvasRef}
        >


        </canvas>
     );
}
 
export default NavCanvas;