import React, { Component } from 'react'
import HorizontalScroll from 'react-scroll-horizontal'
import Canvas from './Canvas'
import { useState } from 'react';
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import { useShoppingCart } from './CartContext';
import { CartItem } from './Cartitem';
import { FormatCurrency } from './FormatCurrency';
import { Link } from 'react-router-dom';
import { MobileCartItem } from './MobileCartitem';


const Shoppingcart = () => {
    const containervariants = {
        hidden : {opacity: 0, x: "100%"}, 
        visible : { opacity: 1, x: "0%",
        transition :{ease:"easeInOut", type: "spring",
        stiffness: 260, damping: 20} }, 
        exit:{ x: "-100%", transition:{ ease: "easeInOut"} }
       } 

       

      const{
        getItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        removeFromCart,
        cartItems
      } =useShoppingCart()
   
   const price = 20.50

   return (
<motion.div className="maincontainer" variants={containervariants} initial="hidden" animate="visible" exit="exit">
<div className="cartnewslidercontent">
<div className="cartholder">
<div className="cartdetailsholder">
<div className="cartproductsectiontitle Yourbagtitlemobile">
Your Bag
</div>
{cartItems.length != 0 ?
<table className="YourCart">
  <tr>
<td>
<div className="cartproductsectiontitle Yourbagtitle">
Your Bag
</div>
</td>

{cartItems.length > 0 && (
<td className="totaldata" rowSpan="4">
  <div className="totalsummaryholder">
 <div className="totallabelholder">
 <div  className="totalproductsectiontitle">Total</div>
 <div className="totalproductsectiontitle">{FormatCurrency(cartItems.reduce((total, cartitem)=>{

   return total + 20.5 * cartitem.quantity;

 }, 0 ))

}</div>
</div>
<div className="checkoutbuttonholder">
 <div className="checkoutbuttonholderinner">
          <div className="buttoninner"></div>
          <Link to="/Checkoutshippingform"><button className='CTAbutton'>CHECKOUT</button></Link>
          </div>
 </div> 
</div>
</td>
)}

</tr>
  {cartItems.map(item=>{
    console.log(item.id);
    return <tr>
      <td><CartItem key={item.id} id={item.id} quantity={item.quantity} color={item.color} size={item.size}></CartItem></td>
      <td><MobileCartItem key={item.id} id={item.id} quantity={item.quantity} color={item.color} size={item.size}></MobileCartItem></td>
      </tr>
  }
    )}    

</table>

: 
<div className="lonely">
  <div className="lonelymessage">YOUR BAG IS EMPTY<br></br>......<br></br> LIKE MY SOUL</div>
  <div className="secondarylonelymessage">Please fill it up.</div>
</div>
 }
</div>
</div>
</div>

</motion.div>
    );
}
 
export default Shoppingcart;