import React, { Component } from 'react'
import HorizontalScroll from 'react-scroll-horizontal'

const Horizontal = () => {
    
   return (


    <div className="horizontallife">
        <HorizontalScroll reverseScroll = { true }>
            <div className ="slider">
            <div className="hero">

<div className="herotitleholder">
     <div className="titleholder">
        <h1>Qreative</h1>
        <br></br>
        <h1>Quirky</h1>
        <br></br>
        <h1>Design</h1>
        <br></br>
        </div>
        <div className="subtitleholder">
            <img src={require("./images/subtitle.png")} alt="" />
            </div>       
</div>
<div className="wrapper">
    <div className="box">
        
    </div>
</div>
<div class="gooey-container">
<img src={require("./images/May the 4th.png")} />
</div>

<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
<defs>
<filter id="goo">
  <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
  <feColorMatrix in="blur" mode="matrix"
    values=" 1 0 0 0 0
             0 1 0 0 0
             0 0 1 0 0
             0 0 0 20 -7" result="gooey" />
  <feComposite in="SourceGraphic" in2="gooey" operator="atop"/>
</filter>
</defs>
</svg>
</div>

            </div>
        </HorizontalScroll>
    
</div>

    );
}
 
export default Horizontal;