import React, { Component } from 'react'
import WorkCanvas from './WorkCanvas';
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import { Link } from 'react-router-dom';

const Trans = () => {
  const containervariants = {
    hidden : {opacity: 0, x: "100%"}, 
    visible : { opacity: 1, x: "0%",
    transition :{ease:"easeInOut", type: "spring",
    stiffness: 260, damping: 20} }, 
    exit:{ x: "-100%", transition:{ ease: "easeInOut"} }
   } 
   return (
<motion.div className="workcontainer" variants={containervariants} initial="hidden" animate="visible" exit="exit">
  <div className="workcontentholder transcindental">
 <div className="overlaytextholder">
  <h2 className="Workmaintitle1"><span>International Day Against Homophobia, Biphobia <br></br> Lesbophobia and Transphobia Art</span></h2>
  <p className= "Workmainpara"><span>Artwork done for the International Day Against Homophobia, Biphobia,Lesbophobia and Transphobia </span></p>
  </div> 
<div className="workcanvasholder">
<WorkCanvas id={"trans1"} width={600} height={1000}></WorkCanvas>
</div>
</div>
<div className="rightcontentsectiontrans transcontent">
  <motion.div initial={{ opacity: 0, x: "-100%"}} animate = {{ opacity: 1, x: "0%" }} transition={{ delay: 4, ease:"easeInandOut"}} className="workimgtrans1">
    <img className="workimgtheway" src={require("./images/trans face 1.png").default} alt="" />
    </motion.div>
  <motion.div initial={{ opacity: 0, x: "-100%"}} animate = {{ opacity: 1, x: "0%" }} transition={{ delay: 4, ease:"easeInandOut"}} className="workimgtrans2">
  <img className='workimgtheway' src={require("./images/trans face 2 (1).png").default} alt="" />
  </motion.div>
  <div className="workimgtrans3">
  <img className='workimgtheway' src={require("./images/trans bottom.png").default} alt="" />
  </div> 
  <div className="arrowsectiontrans">
  <Link className="linknext" to="/tara">
  <img className="Arrowimages" src={require("./images/nextproject.png").default} alt="" />
  <img className="Arrowimages" src={require("./images/aroows.png").default} alt="" />
  </Link>
  </div>
</div>

<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
    <filter id="gooeysvg">
      <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
      <feColorMatrix in="blur" mode="matrix"
        values=" 1 0 0 0 0
                 0 1 0 0 0
                 0 0 1 0 0
                 0 0 0 20 -7" result="gooeysvg" />
      <feComposite in="SourceGraphic" in2="gooeysvg" operator="atop"/>
    </filter>
  </defs>
</svg>
</motion.div> 

    );
}
 
export default Trans;