import React, { Component, useState, useRef } from 'react'
import HorizontalScroll from 'react-scroll-horizontal'
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import emailjs from '@emailjs/browser';
import { useShoppingCart } from './CartContext';
import { CartItem } from './Cartitem';
import { FormatCurrency } from './FormatCurrency';
import { Link } from 'react-router-dom';
import { OrderItem } from './Orderitem';
import { Alert } from 'react-bootstrap';


const Checkoutshippingform = () => {
    console.log(process.env.REACT_APP_EMAIL_SERVICE_ID)
    const containervariants = {
        hidden : {opacity: 0, x: "100%"}, 
        visible : { opacity: 1, x: "0%",
        transition :{ease:"easeInOut", type: "spring",
        stiffness: 260, damping: 20} }, 
        exit:{ x: "-100%", transition:{ ease: "easeInOut"} }
        
       }

    const [USstate, setUSstate] = useState()
    const [Name, setName] =    useState()
    const [Email, setEmail] = useState()
    const [Address1, setAddress1] =    useState()
    const [Address2, setAddress2] = useState()
    const [PostalCode, setPostalCode] =    useState()
    const [City, setCity] = useState()
    const [Phone, setPhone] =    useState()


    const form = useRef();
    const sendDetails = (e) => {
        e.preventDefault();
        setUSstate(form.USstate)
        console.log(USstate)      
      };

      
      const{
        getItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        removeFromCart,
        cartItems
      } =useShoppingCart()
   
   return (
<motion.div className="checkoutmaincontainer" variants={containervariants} initial="hidden" animate="visible" exit="exit">
<div className="checkoutnewslidercontent">
<div className="shippingmain">
<div className="orderinformationholder">
<div className="ordersummaryproductsectiontitle">
Order Summary
</div> 
<div className="orderdetailsholder">
<table className="Yourorder">
  <tr>
<td>
</td>

</tr>
  {cartItems.map(item=>{
    console.log(item.id);
    return <tr>
      <td>
        <OrderItem key={item.id} id={item.id} quantity={item.quantity} color={item.color} size={item.size}></OrderItem></td>   
      </tr>
  }
    )}    
<tr>
  <td colSpan={3}>
    
  </td>
</tr>
</table>

</div>
<div className="ordertotalcalculated">
  <div  className="totalproductsectiontitle">Total</div>
 <div className="totalproductsectiontitle">{FormatCurrency(cartItems.reduce((total, cartitem)=>{

   return total + 20.5 * cartitem.quantity;

 }, 0 ))

}</div>
</div>
</div> 
<div className="shippinginfocontentholder">
    <div className="contactustitle checkoutshippinginformationtitle">
        Shipping information
    </div>
    <div className="shippingformholder">
        <form ref={form} className='checkoutform'>
        <div className="formquestionsection">
        <div className="formquestionitem">
        <label className = "formquestionlabel" htmlFor="">Name</label>
        <input className="shipforminput" type="text" name="name" required value={Name} onChange={(e)=>setName(e.target.value)}/>
        </div>
        <div className="formquestionitem">
        <label  className = "formquestionlabel" htmlFor="">Email</label>
        <input className="shipforminput" type="email" required name="email" value={Email} onChange={(e)=>setEmail(e.target.value)}/>
        </div>
        </div>
        <div className="formquestionsection">
        <div className="formquestionitem">
        <label className="formquestionlabel" htmlFor="">Address 1</label>
        <input className="shipforminput" type="text" required name="Address1" value={Address1} onChange={(e)=>setAddress1(e.target.value)}/>
        </div>
        <div className="formquestionitem">
        <label  className="formquestionlabel" htmlFor="">Address 2</label>
        <input className="shipforminput" type="text" required name="Address2" value={Address2} onChange={(e)=>setAddress2(e.target.value)}/>
        </div>
        </div>
        <div className="postalandstateholder">
        <div className="formquestionitem">
        <label className="Postallabel shippingpostal" htmlFor="">Postal Code</label>
        <input className="shipforminput" type="text" required name="PostalCode" value={PostalCode} onChange={(e)=>setPostalCode(e.target.value)}/>
        </div>
        <div className="formquestionitem">
        <label className="citylabel shippingcity" htmlFor="">City</label>
        <input className="shipforminput" type="text" required name="City" value={City} onChange={(e)=>setCity(e.target.value)}/>
        </div>
        </div>
        <div className="formquestionsection">
        <div className="formquestionitem">
        <label className="formquestionlabel" htmlFor="">State</label>
        <select className="Optionselection" required name="USstate" id="" value={USstate} onChange={(e)=>setUSstate(e.target.value)}>
            <option value="AL">AL - Alabama</option>
            <option value="AK">AK - Alaska</option>
            <option value="AR">AR - Arkansas</option>
            <option value="AZ">AZ - Arizona</option>
            <option value="CA">CA - California</option>
            <option value="CO">CO - Colorado</option>   
            <option value="CT">CT - Connecticut</option>   
            <option value="DE">DE - Delaware</option>   
            <option value="FL">FL - Florida</option>
            <option value="GA">GA - Georgia</option>   
            <option value="HI">HI - Hawaii</option>   
            <option value="ID">ID - Idaho</option>   
            <option value="IL">IL - Illinois</option>                    
            <option value="IA">IA - Iowa</option>   
            <option value="IN">IN - Indiana</option>   
            <option value="KS">KS - Kansas</option>   
            <option value="KY">KY - Kentucky</option>   
            <option value="LA">LA - Louisiana</option>   
            <option value="ME">ME - Maine</option>   
            <option value="MD">MD -  Maryland</option>   
            <option value="MA">MA - Massachusetts</option>   
            <option value="MI">MI - Michigan</option>   
            <option value="MN">MN - Minnesota</option>   
            <option value="MS">MS -  Mississippi</option>
            <option value="MO">MO - Missouri</option>   
            <option value="MT">MT - Montana</option>   
            <option value="NE">NE - Nebraska</option>                    
            <option value="NH">NH - New Hampshire</option>   
            <option value="NV">NV - Nevada</option>   
            <option value="NJ">NJ - New Jersey</option>   
            <option value="NM">NM - New Mexico</option>   
            <option value="NY">NY - New York</option>   
            <option value="NC">NC - North Carolina</option>   
            <option value="ND">ND - North Dakota</option>   
            <option value="OH">OH - Ohio</option>
            <option value="OH">OK - Oklahoma</option>   
            <option value="OR">OR - Oregon</option>   
            <option value="PA">PA - Pennsylvania</option>   
            <option value="RI">RI - Rhode Island</option>
            <option value="SC">SC - South Carolina</option>   
            <option value="SD">SD - South Dakota</option>   
            <option value="TN">TN - Tennessee</option>   
            <option value="TX">TX - Texas</option>   
            <option value="UT">UT - Utah</option>   
            <option value="VT">VT - Vermont</option>   
            <option value="VA">VA - Virginia</option>   
            <option value="WA">WA - Washington</option>   
            <option value="WI">WI - Wisconsin</option>   
            <option value="WV">WV - West Virginia</option>   
            <option value="WY">WY - Wyoming</option>         
        </select>
        </div>
        
        <div className="formquestionitem">             
        <label className="formquestionlabel" htmlFor="">Phone</label>
        <input  className="shipforminput" type="number" required name="Phone" value={Phone} onChange={(e)=>setPhone(e.target.value)}/>
        </div>
        </div>
        <div className="outerbuttonholder">
        <div className="buttonholder1 shippingformbutton">
          <div className="buttoninner"></div>
         { 

         Name != undefined && Email != undefined && Address1 != undefined && Address2 != undefined && PostalCode != undefined && City != undefined && USstate != undefined && Phone != undefined ? 
       <Link to={"/Checkoutpage"}  state = {{ data: {
        Name: Name,
        Email: Email,
        Address1: Address1,
        Address2: Address2,
        PostalCode: PostalCode,
        City: City,
        USstate : USstate,
        Phone: Phone
       }
  }}><button className='CTAbutton'>Proceed to Checkout</button></Link>
  :  
  <button className='CTAbutton'>Proceed to Checkout</button>

}
        </div>
        </div>
        </form>
        </div>
        </div>  

</div>
</div>

</motion.div>
    );
}
 
export default Checkoutshippingform;