import React, { Component } from 'react'
import WorkCanvas from './WorkCanvas';
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import { Link } from 'react-router-dom';

const Coffee = () => {
  const containervariants = {
    hidden : {opacity: 0, x: "100%"}, 
    visible : { opacity: 1, x: "0%",
    transition :{ease:"easeInOut", type: "spring",
    stiffness: 260, damping: 20} }, 
    exit:{ x: "-100%", transition:{ ease: "easeInOut"} }
   } 
   return (

<motion.div className="workcontainer" variants={containervariants} initial="hidden" animate="visible" exit="exit">
  <div className="workcontentholder Coffeework">
 <div className="overlaytextholder">
  <h2 className="Workmaintitle2"><span>'Coffee' Merch Collection</span></h2>
  <p className= "Workmainpara"><span>Treat yourself or your favorite coffee lover to our merch collection today and show the world just how much you love your daily cup of joe.<br></br><br></br>
   It's the perfect way to add a little bit of coffee-inspired fun to your wardrobe and your life!  
        </span></p>
  </div> 
<div className="workcanvasholder">
<WorkCanvas id={"scream1"} width={600} height={600}></WorkCanvas>
</div>
</div>
<div className="rightcontentsection coffeeworkrightsection">
  <div className="workcontenttheway1">
    <img className="workimgtheway1" src={require("./images/Group 9 (1).png").default} alt="" />
    </div>
  <div className="workcontenttheway2">
  <img className='workimgtheway' src={require("./images/drink coffee1.png").default} alt="" />
  </div>
  <div className="workcontenttheway3">
  <img className='workimgtheway' src={require("./images/drink coffee2.png").default} alt="" />
  </div>
  <div className="workcontenttheway4">
  <img className='workimgtheway' src={require("./images/drink coffee3.png").default} alt="" />
  </div>
  <div className="workcontenttheway5">
  <img className='workimgtheway1' src={require("./images/Group 8 (1).png").default} alt="" />
  </div>
  <div className="arrowsection">
  <Link className="linknext" to="/Halloween">
  <img className="Arrowimages" src={require("./images/nextproject.png").default} alt="" />
  <img className="Arrowimages" src={require("./images/aroows.png").default} alt="" />
  </Link>
  </div>
</div>

<svg xmlns="http://www.w3.org/2000/svg" version="1.1">
  <defs>
    <filter id="gooeysvg">
      <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
      <feColorMatrix in="blur" mode="matrix"
        values=" 1 0 0 0 0
                 0 1 0 0 0
                 0 0 1 0 0
                 0 0 0 20 -7" result="gooeysvg" />
      <feComposite in="SourceGraphic" in2="gooeysvg" operator="atop"/>
    </filter>
  </defs>
</svg>
</motion.div>  

    );
}
 
export default Coffee;